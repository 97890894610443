import { inject, InjectionKey, provide, ref, shallowRef } from 'vue'

export interface ContactFormData {
  subject?: string
  message?: string
  additionalInformation?: string
}

interface CONTACT_FORM_CONTEXT {
  /**
   * Opens the contact form with the option to add initial content.
   *
   * @param {ContactFormData} data
   */
  open(data?: ContactFormData): void

  /**
   * Closes the contact form.
   */
  close(): void
}

const CONTACT_FORM_CONTEXT_KEY: InjectionKey<CONTACT_FORM_CONTEXT> = Symbol(
  'CONTACT_FORM_CONTEXT_KEY'
)

/**
 * Function to set up the contact form context within the `<ContactFormProvider />`.
 *
 * @export
 * @returns
 */
export function setupContactForm() {
  const isContactFormLoaded = ref(false)
  const isContactFormOpen = ref(false)

  const contactFormData = shallowRef<ContactFormData>()
  const success = ref(false)

  const ctx = {
    open(data?: ContactFormData) {
      success.value = false
      contactFormData.value = data
      isContactFormLoaded.value = true
      isContactFormOpen.value = true
    },
    close() {
      contactFormData.value = undefined
      isContactFormOpen.value = false
    },
  }

  provide(CONTACT_FORM_CONTEXT_KEY, ctx)

  return { isContactFormLoaded, isContactFormOpen, contactFormData, success, ...ctx }
}

/**
 * Hook to access the `open()` and `close()` methods for the apps contact form.
 * The contact form must be setup and available through the `<ContactFormProvider />`
 *
 * @export
 * @returns
 */
export function useContactForm() {
  const ctx = inject(CONTACT_FORM_CONTEXT_KEY)

  if (!ctx) {
    throw new TypeError('Component must be wrapped with a `ContactFormProvider`.')
  }

  return ctx
}
